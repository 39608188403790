import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../lib/ag-grid';
import PageWrapperForProjects from '../PageWrapperForProjects';
import Table from '../reusable/Tables/Table';
import { fetchSessions, setSelectedSessions, deleteSessions } from '../../store/sessions/actions';
import SessionHeader from './SessionHeader';
import BulkActionsSidebar from '../BulkActionsSidebar';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import sessionsBulkActions from '../../table/bulkActions/sessions';
import breadcrumbs from './sessions.breadcrumbs';
import * as actions from '../../table/actionsList';
import useModals from '../../hooks/useModals';
import { deleteProjects, end360Project, fetch360ProjectById, setSelectedProjects } from '../../store/projects/actions';
import ConfirmationModal from '../reusable/ConfirmationModal';
import AddSessionModal from './AddSessionModal';
import SessionInvite from './SessionInvite';
import RequestReport360 from '../RequestReport360/RequestReport360';
import AddProjectsModal from '../Projects/AddProjectsModal';
import ImportSessions from './ImportSessions';
import CustomButton from '../reusable/Buttons/Button';
import { ReactComponent as ProjectsIcon } from '../../assets/icons/projects.svg';
import { ReactComponent as GearIcon } from '../../assets/icons/gear.svg';
import { SpecifyOwnRatersInvite } from './SpecifyOwnRatersInvite';
import AssessmentProjectStatus from '../Projects/AssessmentProject/components/AssessmentProjectStatus';
import { AP_COMPLETED_STATUS } from '../../constants/assessmentProjectStatuses';
import isChineseEnv from '../../utils/isChineseEnv';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const selfStatuses = {
  invited: {
    title: I18n.t('Invited'),
    background: '#DFF6FF',
    color: '#008AC0',
  },
  completed: {
    title: I18n.t('Completed'),
    background: '#ECF6EF',
    color: '#27AE60',
  },
  notSent: {
    title: I18n.t('Invite Not Sent'),
    background: '#F9F9F9',
    color: '#5B5B5B',
  },
};

const StyledStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 57px;
`;

const StyledStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  height: 14px;
  width: 110px;
  font-weight: 600;
  font-size: 8px;
  text-transform: uppercase;
  border-radius: 4px;
  line-height: 1;
`;

const StyledStatusTitle = styled.div`
  margin-bottom: 5px;
  line-height: 1;
`;

const SelfCell = ({ data }) => {
  const { firstName, familyName, completeStatus, inviteStatus } = data.self;

  // eslint-disable-next-line
  const status = selfStatuses[completeStatus ? 'completed' : inviteStatus ? 'invited' : 'notSent'];

  return (
    <StyledStatusWrapper>
      <StyledStatusTitle>{isChineseEnv ? `${familyName}${firstName}` : `${firstName} ${familyName}`}</StyledStatusTitle>
      <StyledStatus style={{ color: status.color, backgroundColor: status.background }}>
        {I18n.t(status.title)}
      </StyledStatus>
    </StyledStatusWrapper>
  );
};

const RatersProgressCell = ({ data, raterType }) => {
  const raters = data.raters.filter((r) => r.role === raterType);
  const invited = raters.filter((r) => r.inviteStatus === 1);
  const completed = raters.filter((r) => r.completeStatus === 1);

  const isZeroRaters = raters.length === 0;
  const isAllCompleted = completed.length === raters.length;
  const isAllInvited = invited.length === raters.length;

  return (
    <StyledStatusWrapper>
      <ProgressCellItem isZeroRaters={isZeroRaters} isAllInvited={isAllInvited}>
        {I18n.t('Invited')} <span>{`${invited.length}/${raters.length}`}</span>
      </ProgressCellItem>
      <ProgressCellItem isZeroRaters={isZeroRaters} isAllCompleted={isAllCompleted}>
        {I18n.t('Completed')} <span>{`${completed.length}/${raters.length}`}</span>
      </ProgressCellItem>
    </StyledStatusWrapper>
  );
};

const ProgressCell = ({ data }) => {
  let color = 0;
  const colorsMap = ['#FF574C', '#F3A055', '#FFDF6D', '#56CCF2', '#27AEA6', '#6FCF97'];
  const { progress } = data;

  switch (true) {
    case progress === 1:
      color = 5;
      break;
    case progress > 0.75:
      color = 4;
      break;
    case progress > 0.5:
      color = 3;
      break;
    case progress > 0.25:
      color = 2;
      break;
    case progress > 0:
      color = 1;
      break;
    default:
      color = 0;
  }

  return (
    <StyledCell>
      <StyledCircle color={colorsMap[color]} />
      <span>{`${parseInt(progress, 10)} %`}</span>
    </StyledCell>
  );
};

const Sessions = ({ projectId }) => {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state.sessions.sessions);
  const selectedSessions = useSelector((state) => state.sessions.selectedSessions);
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);

  const gridRef = useRef();

  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isLoading, setLoadingStatus] = useState(true);
  // needed for invite sessions modal; it's quite complicated and requires different flags for certain types of behaviour
  const [projectScopeEnabled, setProjectScope] = useState(false);
  const [commandType, setCommandType] = useState(0);
  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedSessions });

  const modals = [
    actions.ADD_SESSION,
    actions.DELETE_SESSIONS,
    actions.SESSION_INVITE_RATERS,
    actions.SESSION_INVITE_SPECIFY_RATERS,
    actions.PROJECT_REQUEST_REPORT,
    actions.EDIT_PROJECT_DETAILS,
    actions.IMPORT_SESSIONS,
    actions.DELETE_PROJECTS,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);
  const completeProject = () => {
    dispatch(
      end360Project(projectId, (err) => {
        if (!err) dispatch(push('/projects'));
      }),
    );
  };
  const onProjectDelete = () => {
    dispatch(
      deleteProjects([{ projectId: Number(projectId), type: 0 }], () => {
        closeModal(actions.DELETE_PROJECTS);
        dispatch(push(`/projects`));
      }),
    );
  };

  const onSessionsDelete = () => {
    dispatch(
      deleteSessions(
        projectId,
        selectedSessions.map((p) => p.sessionId),
        () => {
          closeModal(actions.DELETE_SESSIONS);
          dispatch(fetch360ProjectById(projectId));
        },
      ),
    );
  };

  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    const args = [
      tableData,
      {
        callback: () => {
          setLoadingStatus(false);
          tableCallback && tableCallback();
        },
        shouldReset,
      },
    ];

    dispatch(fetchSessions(projectId, ...args));
  }, []);

  const navigateToEdit = (session) => {
    dispatch(push(`/projects/360/${projectId}/session/${session.sessionId}/session-raters`));
    dispatch(setSelectedProjects([]));
  };

  useEffect(() => {
    if (projectId) dispatch(fetch360ProjectById(projectId));
  }, [projectId, shouldResetPage]);

  // table actions
  const tableHandlers = {
    [actions.ADD_SESSION]: () => openModal(actions.ADD_SESSION),
    [actions.IMPORT_SESSIONS]: () => openModal(actions.IMPORT_SESSIONS),
  };

  // "commandType":
  //
  // 0 - all incomplete
  //
  // 1 - all uninvited, and not complete
  //
  // 2 - already invited, but not complete

  const tilesActionsHandlers = {
    [actions.PROJECT_INVITE_UNINVITED_SESSIONS]: () => {
      setCommandType(1);
      setProjectScope(true);
      openModal(actions.SESSION_INVITE_RATERS);
    },
    [actions.PROJECT_INVITE_INVITED_SESSIONS]: () => {
      setCommandType(2);
      setProjectScope(true);
      openModal(actions.SESSION_INVITE_RATERS);
    },
    [actions.SESSION_REQUEST_REPORT]: () => {
      setProjectScope(true);
      openModal(actions.SESSION_REQUEST_REPORT);
    },
  };

  const eligibleToRequestReportSessions = projectScopeEnabled
    ? activeProjectInView.sessions.filter((item) => item.completed)
    : selectedSessions.filter((session) => session.self.completeStatus);

  const bulkActions = useMemo(() => {
    return sessionsBulkActions.map((item) => ({
      ...item,
      handler: () => {
        if (item.name === actions.SESSION_INVITE_RATERS) setCommandType(item.commandType);
        openModal(item.name);
      },
      isDisabled:
        typeof item.isDisabled === 'function'
          ? item.isDisabled(selectedSessions, activeProjectInView)
          : item.isDisabled,
    }));
  }, [selectedSessions, activeProjectInView]);

  const onBackButtonClick = () => {
    setShouldResetPageStatus(true);
    dispatch(push('/projects'));
  };

  const title = activeProjectInView ? activeProjectInView.name : I18n.t('Sessions');

  const Buttons = () => (
    <ButtonsWrapper>
      <StyledButton
        size="s"
        onClick={tableHandlers[actions.IMPORT_SESSIONS]}
        disabled={activeProjectInView?.status === AP_COMPLETED_STATUS}
      >
        {I18n.t('Import sessions')}
      </StyledButton>
      <StyledButton
        size="s"
        width={130}
        onClick={tableHandlers[actions.ADD_SESSION]}
        disabled={activeProjectInView?.status === AP_COMPLETED_STATUS}
      >
        {I18n.t('Add session')}
      </StyledButton>
    </ButtonsWrapper>
  );

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedSessions(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: false,
    filter: false,
  };

  const [columnDefinition] = useState([
    {
      field: 'self',
      headerName: I18n.t('Self'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      sortable: true,
      valueGetter: ({ data }) =>
        isChineseEnv
          ? `${data.self.familyName}${data.self.firstName}`
          : `${data.self.firstName} ${data.self.familyName}`,
      cellRenderer: ({ data }) => <SelfCell data={data} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName:
        activeProjectInView && activeProjectInView.managerAlias ? activeProjectInView.managerAlias : I18n.t('Manager'),
      cellDataType: 'text',
      sortable: false,
      filter: false,
      flex: 1,
      valueGetter: ({ data }) => data.raters.filter((r) => r.role === 1),
      cellRenderer: ({ data }) => <RatersProgressCell data={data} raterType={1} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: activeProjectInView && activeProjectInView.peerAlias ? activeProjectInView.peerAlias : I18n.t('Peer'),
      celldatatype: 'text',
      flex: 1,
      sortable: false,
      filter: false,
      valueGetter: ({ data }) => data.raters.filter((r) => r.role === 2),
      cellRenderer: ({ data }) => <RatersProgressCell data={data} raterType={2} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName:
        activeProjectInView && activeProjectInView.directReportAlias
          ? activeProjectInView.directReportAlias
          : I18n.t('Direct Report'),
      cellDataType: 'text',
      flex: 1,
      sortable: false,
      filter: false,
      valueGetter: ({ data }) => data.raters.filter((r) => r.role === 3),
      cellRenderer: ({ data }) => <RatersProgressCell data={data} raterType={3} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName:
        activeProjectInView && activeProjectInView.otherAlias ? activeProjectInView.otherAlias : I18n.t('Other'),
      cellDataType: 'text',
      flex: 1,
      sortable: false,
      filter: false,
      valueGetter: ({ data }) => data.raters.filter((r) => r.role === 4),
      cellRenderer: ({ data }) => <RatersProgressCell data={data} raterType={4} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: I18n.t('Progress'),
      cellDataType: 'text',
      flex: 1,
      sortable: false,
      filter: false,
      valueGetter: ({ data }) => `${data.progress}%`,
      cellRenderer: ({ data }) => <ProgressCell data={data} />,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: I18n.t('Creation Date'),
      cellDataType: 'date',
      filter: 'agDateColumnFilter',
      flex: 1,
      sortable: true,
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToEdit(event.data);
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, []);

  useEffect(() => {
    if (!isLoading && shouldResetPage && gridRef.current) {
      setLoadingStatus(true);
        fetchData({
          shouldReset: true,
          showSpinner: true,
          tableCallback: () => {
            if (gridRef.current?.api) {
              gridRef.current.api.setGridOption('rowData', sessions);
              gridRef.current.api.refreshCells();
            }
          },
        });
        setShouldResetPageStatus(false)
    }
  }, [shouldResetPage]);

  return (
    <PageWrapperForProjects
      breadcrumbs={breadcrumbs(activeProjectInView)}
      title={
        <TitleComponent>
          <ProjectsIcon />
          <span>{title}</span>
          <AssessmentProjectStatus status={Number(activeProjectInView?.status)} />
        </TitleComponent>
      }
      backButtonHandler={onBackButtonClick}
      customButton={
        <SettingsRow>
          <StyledButton
            size="s"
            handler={() => openModal(actions.END_360_PROJECT)}
            disabled={Number(activeProjectInView?.status) === AP_COMPLETED_STATUS}
          >
            {I18n.t('End project')}
          </StyledButton>
          <StyledGearIcon onClick={() => openModal(actions.EDIT_PROJECT_DETAILS)} />
        </SettingsRow>
      }
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!!activeProjectInView && <SessionHeader project={activeProjectInView} actions={tilesActionsHandlers} />}
      <TableHeaderRow>
        <TableName>{I18n.t('Sessions list')}</TableName>
        <Buttons />
      </TableHeaderRow>
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : sessions}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          loading={isLoading ? true : false}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_SESSIONS]}
        title={selectedSessions.length === 1 ? I18n.t('Delete session') : I18n.t('Delete sessions')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedSessions.length === 1 ? I18n.t('this session?') : I18n.t('selected sessions?')
        }`}
        onClose={() => closeModal(actions.DELETE_SESSIONS)}
        onConfirm={onSessionsDelete}
        caution
      />
      {modalsState[actions.ADD_SESSION] && (
        <AddSessionModal
          onClose={() => closeModal(actions.ADD_SESSION)}
          onSuccess={() => setShouldResetPageStatus(true)}
          projectId={projectId}
        />
      )}

      {modalsState[actions.SESSION_REQUEST_REPORT] && (
        <RequestReport360
          onClose={() => closeModal(actions.SESSION_REQUEST_REPORT)}
          eligibleSessions={eligibleToRequestReportSessions}
          projectId={projectId}
          successCallback={() => {
            if (projectScopeEnabled) setProjectScope(false);
          }}
        />
      )}
      {modalsState[actions.SESSION_INVITE_RATERS] && (
        <SessionInvite
          onClose={() => closeModal(actions.SESSION_INVITE_RATERS)}
          itemType="session"
          items={selectedSessions}
          commandType={commandType}
          projectScopeEnabled={projectScopeEnabled}
          successCallback={() => {
            setShouldResetPageStatus(true);
            if (projectScopeEnabled) setProjectScope(false);
          }}
        />
      )}
      {modalsState[actions.SESSION_INVITE_SPECIFY_RATERS] && (
        <SpecifyOwnRatersInvite
          onClose={() => closeModal(actions.SESSION_INVITE_SPECIFY_RATERS)}
          items={selectedSessions}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}

      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_PROJECTS]}
        title={I18n.t('Delete project')}
        description={I18n.t('Are you sure you want to delete this project')}
        onClose={() => closeModal(actions.DELETE_PROJECTS)}
        onConfirm={onProjectDelete}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.END_360_PROJECT]}
        title={I18n.t('End project')}
        description={I18n.t('You are about to end a project')}
        onClose={() => closeModal(actions.END_360_PROJECT)}
        onConfirm={completeProject}
        caution
      />

      {modalsState[actions.IMPORT_SESSIONS] && (
        <ImportSessions
          onClose={() => closeModal(actions.IMPORT_SESSIONS)}
          onSuccess={() => setShouldResetPageStatus(true)}
        />
      )}

      {activeProjectInView && modalsState[actions.EDIT_PROJECT_DETAILS] && (
        <AddProjectsModal
          onClose={() => closeModal(actions.EDIT_PROJECT_DETAILS)}
          onReset={setShouldResetPageStatus}
          selectedProjects={[activeProjectInView]}
        />
      )}
    </PageWrapperForProjects>
  );
};

const TableHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
const TableName = styled.h3`
  font-size: ${(props) => props.theme.fontSizes.normal};
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
`;

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;
const TitleComponent = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;
const StyledGearIcon = styled(GearIcon)`
  &:hover {
    cursor: pointer;
  }
`;

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  gap: 16px;
`;

const ProgressCellItem = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #295368;
  
  span {
    color: #FF574C;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-left: 8px;
    letter-spacing: 0.1em;
    
    ${(props) =>
      props.isAllInvited &&
      css`
        color: #27ae60;
      `}
    
    ${(props) =>
      props.isAllCompleted &&
      css`
        color: #27ae60;
      `}
    
    ${(props) =>
      props.isZeroRaters &&
      css`
        color: #cdcdcd;
      `}
  }
`;

const StyledCell = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCircle = styled.i`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

export default Sessions;
