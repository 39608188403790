import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'redux-first-history';
import { number, oneOfType, string } from 'prop-types';
import { I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../lib/ag-grid';
import PageWrapperForProjects from '../../PageWrapperForProjects';
import Table from '../../reusable/Tables/Table';
import {
  fetchAssessmentProjectRespondents,
  setAssessmentProjectSelectedRespondents,
  setAssessmentProjectSearchValue,
  fetchAssessmentProjectById,
  setActiveProject,
  assessmentProjectInviteAllRespondents,
  assessmentProjectInviteRespondents,
  assessmentProjectDeleteRespondents,
  resetAssessmentProjectData,
  assessmentProjectHashCheck,
  endAssessmentProject,
  sendAssessmentProjectReminder,
} from '../../../store/projects/actions';
import { fetchUser } from '../../../store/user/actions';
import assessmentProjectRespondentsColumns from '../../../table/columns/assessment-project-respondents';
import AssessmentProjectHeader from './AssessmentProjectHeader';
import AdvancedFilter from '../../reusable/AdvancedFilter';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import assessmentProjectRespondentsBulkActions from '../../../table/bulkActions/assessmentProjectRespondents';
import breadcrumbs from '../assessment-projects.breadcrumbs';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import ImportRespondentsModal from '../../Respondents/ImportRespondents';
import AssessmentProjectAddRespondentsModal from './AssessmentProjectAddRespondents';
import AssessmentProjectTitleRow from './AssessmentProjectTitleRow';
import NoProjectModal from './NoProjectModal';
import CustomButton from '../../reusable/Buttons/Button';
import AssessmentProjectStatus from './components/AssessmentProjectStatus';

import {
  downloadZippedAssessmentProjectReports,
  downloadAssessmentProjectRespondentReports,
  downloadAssessmentProjectInvites,
} from '../../Reports/reports.utils';
import { ReactComponent as ProjectsIcon } from '../../../assets/icons/projects.svg';
import { ReactComponent as EmptyTableIcon } from '../../../assets/icons/empty-state-grey.svg';
import { ReactComponent as NoResultsIcon } from '../../../assets/icons/empty_state.svg';
import { createCopiedNotification } from '../../Invites/Invites';
import { AP_COMPLETED_STATUS } from '../../../constants/assessmentProjectStatuses';
import isChineseEnv from '../../../utils/isChineseEnv';
import inviteStatuses from '../../../constants/inviteStatuses';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-blue.svg';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const statusOptions = Object.keys(inviteStatuses).map((key) => ({ value: key, label: inviteStatuses[key] }));

const bgStatusColors = {
  0: 'rgba(0,0,0,0.08)',
  1: '#E0F1F7',
  2: '#008AC0',
  3: 'rgba(176, 0, 32, 0,25)',
  4: 'rgba(176, 0, 32, 0,25)',
  6: '#B00020',
  7: '#0B8D00',
};

const statusFontColors = {
  0: 'rgba(0,0,0,0.6)',
  1: '#008AC0',
  2: '#FFF',
  3: '#B00020',
  4: '#B00020',
  6: '#FFF',
  7: '#FFF',
};

const reportStatusesLabels = (reportStatuses) => {
  const availableReportsLength = reportStatuses ? reportStatuses.filter((item) => item.reportStatus === 1).length : 0;
  return {
    0: I18n.t('Unavailable'),
    1: I18n.t('Available'),
    2: I18n.t('Failed'),
    3: I18n.t('Pending'),
    4: `${availableReportsLength}/${reportStatuses?.length} ${I18n.t('Available')}`,
  };
};

const reportStatusesColors = {
  0: 'rgba(0, 0, 0, 0.4)',
  1: '#0B8D00',
  2: '#B00020',
  3: '#E37F09',
  4: '#0B8D00',
};

const resolveReportStatus = (reportStatuses) => {
  if (!reportStatuses || !reportStatuses.length) return 0;
  if (reportStatuses.every((item) => item.reportStatus === 1)) return 1;
  if (reportStatuses.every((item) => item.reportStatus === 2)) return 2;
  if (reportStatuses.some((item) => item.reportStatus === 0)) return 3;
  if (reportStatuses.some((item) => item.reportStatus === 2)) return 4;
};

const AssessmentProject = ({ projectId }) => {
  const dispatch = useDispatch();

  const respondents = useSelector((state) => state.projects.assessmentProject.respondents);
  const selectedRespondents = useSelector((state) => state.projects.assessmentProject.selectedRespondents);
  const pageCount = useSelector((state) => state.projects.assessmentProject.pagesAvailable);
  const totalCount = useSelector((state) => state.projects.assessmentProject.totalCount);
  const searchValue = useSelector((state) => state.projects.assessmentProject.search);
  const lastPageIndex = useSelector((state) => state.projects.assessmentProject.lastPageIndex);
  const activeProjectInView = useSelector((state) => state.projects.activeProjectInView);
  const [shouldResetPage, setShouldResetPageStatus] = useState(false);
  const [isTableLoading, setTableLoadingStatus] = useState(true);
  const [isInfoLoading, setInfoLoadingStatus] = useState(true);

  const gridRef = useRef();

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedRespondents });

  const modals = [
    actions.ASSESSMENT_PROJECT_ADD_RESPONDENT,
    actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS,
    actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS,
    actions.NO_PROJECT_MODAL,
    actions.END_ASSESSMENT_PROJECT,
    actions.ASSESSMENT_PROJECT_SEND_REMINDERS,
  ];

  const { modalsState, openModal, closeModal } = useModals(modals);

  const fetchData = useCallback(
    ({ tableData, shouldReset, showSpinner, tableCallback }) => {
      if (!projectId) return;
      if (showSpinner) setTableLoadingStatus(true);
      const args = [
        tableData,
        {
          callback: () => {
            setTableLoadingStatus(false);
            tableCallback && tableCallback();
          },
          shouldReset,
          projectId,
        },
      ];

      dispatch(fetchAssessmentProjectRespondents(...args));
    },
    [projectId],
  );

  const columns = useMemo(() => {
    return assessmentProjectRespondentsColumns;
  }, []);

  const fetchProject = useCallback(() => {
    if (projectId)
      dispatch(
        fetchAssessmentProjectById(projectId, (e) => {
          if (e && e.message.includes('404')) openModal(actions.NO_PROJECT_MODAL);
          setInfoLoadingStatus(false);
        }),
      );
  }, [projectId]);

  useEffect(() => {
    fetchProject();
    return () => dispatch(setActiveProject(null));
  }, []);

  useEffect(() => {
    if (!projectId) return;
    const intervalId = setInterval(() => {
      dispatch(
        assessmentProjectHashCheck(projectId, (err) => {
          if (!err) {
            fetchProject();
            setShouldResetPageStatus(true);
          }
        }),
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const onSelectChange = useCallback((data) => {
    dispatch(setAssessmentProjectSelectedRespondents(data));
  }, []);

  const memoizedSetAssessmentProjectSearchValue = useCallback(
    (val) => dispatch(setAssessmentProjectSearchValue(val)),
    [],
  );

  const inviteAllUninvitedRespondents = () =>
    dispatch(
      assessmentProjectInviteAllRespondents(projectId, () => {
        closeModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS);
        setShouldResetPageStatus(true);
        fetchProject();
      }),
    );

  const inviteRespondents = () =>
    dispatch(
      assessmentProjectInviteRespondents(
        projectId,
        selectedRespondents.map((item) => item.respondentID),
        () => {
          closeModal(actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS);
          setShouldResetPageStatus(true);
          fetchProject();
        },
      ),
    );

  const removeRespondents = () => {
    dispatch(
      assessmentProjectDeleteRespondents(
        projectId,
        selectedRespondents.map((resp) => resp.id),
        () => {
          closeModal(actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS);
          dispatch(fetchUser());
          fetchProject();
          setShouldResetPageStatus(true);
        },
      ),
    );
  };

  const completeProject = () => {
    dispatch(
      endAssessmentProject(projectId, (err) => {
        if (!err) dispatch(push('/projects'));
      }),
    );
  };
  const sendReminders = () => {
    const respondentsToInvite = selectedRespondents.filter((r) => !r.inviteID).map((item) => item.respondentID);
    if (respondentsToInvite.length > 0) {
      dispatch(
        assessmentProjectInviteRespondents(projectId, respondentsToInvite, () => {
          closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS);
          setShouldResetPageStatus(true);
          fetchProject();
        }),
      );
    }

    const respondentsToRemind = selectedRespondents.filter((r) => r.inviteID).map((item) => item.inviteID);
    if (respondentsToRemind.length > 0) {
      dispatch(
        sendAssessmentProjectReminder(projectId, respondentsToRemind, () => {
          closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS);
        }),
      );
    }
  };

  const goToSettings = () => dispatch(push(`/projects/assessment/${projectId}/settings`));

  const tilesActionsHandlers = {
    [actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]: () =>
      openModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS),
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_REPORTS]: () => downloadZippedAssessmentProjectReports(projectId),
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_INVITES_CSV]: () => downloadAssessmentProjectInvites(activeProjectInView),
  };

  const bulkActionsHandlers = {
    [actions.ASSESSMENT_PROJECT_COPY_INVITE_LINK]: () => {
      copy(selectedRespondents[0].invitationLink);
      createCopiedNotification();
    },
    [actions.ASSESSMENT_PROJECT_DOWNLOAD_RESPONDENT_REPORTS]: () => {
      downloadAssessmentProjectRespondentReports(projectId, selectedRespondents[0].respondentID);
    },
    [actions.ASSESSMENT_PROJECT_GO_TO_RESPONDENT_PROFILE]: () =>
      window.open(
        `${window._env_.REACT_APP_MANAGEMENT_URL}/respondents/${selectedRespondents[0].respondentID}`,
        '_blank',
      ),
  };

  const bulkActions = useMemo(() => {
    return assessmentProjectRespondentsBulkActions(activeProjectInView).map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name] || (() => openModal(item.name)),
      hint: typeof item.hint === 'function' ? item.hint(selectedRespondents) : item.hint,
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedRespondents) : item.isDisabled,
    }));
  }, [selectedRespondents, activeProjectInView]);

  // initial table state
  const initialState = useMemo(() => ({ sortBy: [{ id: 'Invited', desc: true }] }), []);

  const onBackButtonClick = () => {
    dispatch(resetAssessmentProjectData());
    dispatch(push('/projects'));
  };

  const title = activeProjectInView ? activeProjectInView.name : I18n.t('Assessment project');
  const isProjectCompleted = Number(activeProjectInView?.status) === AP_COMPLETED_STATUS;

  const Buttons = () => (
    <ButtonsWrapper>
      <StyledButton
        size="s"
        disabled={isProjectCompleted}
        onClick={() => openModal(actions.ASSESSMENT_PROJECT_ADD_RESPONDENT)}
      >
        {I18n.t('Add respondents')}
      </StyledButton>
      <StyledButton
        size="s"
        disabled={isProjectCompleted}
        onClick={() => openModal(actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS)}
      >
        {I18n.t('Import')}
      </StyledButton>
    </ButtonsWrapper>
  );

  const EmptyTableMessage = () => (
    <EmptyMessageTableWrapper>
      {searchValue ? <StyledNoResultsIcon /> : <EmptyTableIcon />}
      {searchValue ? (
        <EmptyMessageTitle minimize={searchValue}>
          {I18n.t("Sorry, we couldn't find any respondents")}
        </EmptyMessageTitle>
      ) : (
        <NoRespondentsMessage>
          <div>{I18n.t('There are no respondents added')}</div>
          <div>{I18n.t('You may start adding respondents via add or import respondents actions')}</div>
        </NoRespondentsMessage>
      )}
    </EmptyMessageTableWrapper>
  );

  const projectCost =
    activeProjectInView?.status === AP_COMPLETED_STATUS
      ? activeProjectInView?.realProjectCost
      : activeProjectInView?.totalCreditsCost;

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setAssessmentProjectSelectedRespondents(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
    filter: false,
  };

  const [columnDefinition] = useState([
    {
      headerName: I18n.t('PersonName'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      valueGetter: (params) => {
        const fullName = isChineseEnv
          ? `${params.data.familyName}${params.data.firstName}`
          : `${params.data.firstName} ${params.data.familyName}`;
        return fullName;
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'email',
      headerName: I18n.t('Email Address'),
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'invitedDate',
      headerName: I18n.t('Invited'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        if (!params.data.invitedDate) return null;
        return new Date(params.data.invitedDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'expiredDate',
      headerName: I18n.t('Invite expires on'),
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        if (!params.data.expiredDate) return null;
        return new Date(params.data.expiredDate);
      },
      cellRenderer: ({ value, data }) => (data.completeStatus === 1 ? '-' : dateValueFormatter({ value })),
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'status',
      headerName: I18n.t('Status'),
      cellRenderer: ({ data }) => (
        <StyledStatusWrapper>
          <StyledStatus bgColor={bgStatusColors[data.status]} fontColor={statusFontColors[data.status]}>
            {I18n.t(statusOptions[data.status].label)}
          </StyledStatus>
        </StyledStatusWrapper>
      ),
    },
    {
      field: 'status',
      headerName: I18n.t('Reports'),
      cellRenderer: ({ data }) => {
        const status = resolveReportStatus(data.reportStatuses);
        const labels = reportStatusesLabels(data.reportStatuses);
        return (
          <ReportStatus color={reportStatusesColors[status]}>
            <span>{labels[status]}</span>
            {status === 4 && (
              <>
                <RedWarningIcon data-tip data-for="warning" />{' '}
                <StyledTooltip id="warning">
                  {`${I18n.t(
                    'Some of the reports may have failed or are unavailable due to incomplete results',
                  )}. ${I18n.t('Please check respondent profile for details')}.`}
                </StyledTooltip>
              </>
            )}
          </ReportStatus>
        );
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, []);

  useEffect(() => {
    if (!isTableLoading && shouldResetPage && gridRef.current) {
      setTableLoadingStatus(true);
        fetchData({
          shouldReset: true,
          showSpinner: true,
          tableCallback: () => {
            if (gridRef.current?.api) {
              gridRef.current.api.setGridOption('rowData', projects);
              gridRef.current.api.refreshCells();
            }
          },
        });
        setShouldResetPageStatus(false)
    }
  }, [shouldResetPage]);

  return (
    <PageWrapperForProjects
      breadcrumbs={breadcrumbs(activeProjectInView)}
      title={
        <TitleComponent>
          <ProjectsIcon />
          <span>{title}</span>
          <AssessmentProjectStatus status={Number(activeProjectInView?.status)} />
        </TitleComponent>
      }
      backButtonHandler={onBackButtonClick}
      customButton={
        <AssessmentProjectTitleRow
          project={activeProjectInView}
          onGearClick={goToSettings}
          onEndProject={() => openModal(actions.END_ASSESSMENT_PROJECT)}
        />
      }
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      {!!activeProjectInView && (
        <AssessmentProjectHeader
          project={activeProjectInView}
          isLoading={isInfoLoading}
          onInvite={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]}
          onDownload={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_DOWNLOAD_REPORTS]}
          onInvitesDownload={tilesActionsHandlers[actions.ASSESSMENT_PROJECT_DOWNLOAD_INVITES_CSV]}
        />
      )}
      <TableHeaderRow>
        <RowWrapper>
          <TableName>
            {I18n.t('Respondents')} ({totalCount})
          </TableName>
          <ProjectCost>
            <span>
              {activeProjectInView?.status === AP_COMPLETED_STATUS
                ? I18n.t('Project cost')
                : I18n.t('Estimated project cost')}
              :
            </span>
            <span>
              {projectCost} {I18n.t('credits')}
            </span>
          </ProjectCost>
        </RowWrapper>
        <Buttons />
      </TableHeaderRow>
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isTableLoading ? null : respondents}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          loading={isTableLoading ? true : false}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS]}
        title={I18n.t('Invite respondents')}
        description={`${I18n.t('You are about to send an invite to all respondents in the project')}. ${I18n.t(
          'Press “Yes” to proceed with the action',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_INVITE_ALL_RESPONDENTS)}
        onConfirm={inviteAllUninvitedRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS]}
        title={I18n.t('Invite respondent(s)')}
        description={`${I18n.t('You are about to send invites to selected respondents')}. ${I18n.t(
          'Press “Yes” to continue',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_INVITE_RESPONDENTS)}
        onConfirm={inviteRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS]}
        title={I18n.t('Remove respondents')}
        description={I18n.t('Are you sure you want to remove selected respondents?')}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_DELETE_RESPONDENTS)}
        onConfirm={removeRespondents}
        caution
      />

      <ConfirmationModal
        isVisible={modalsState[actions.END_ASSESSMENT_PROJECT]}
        title={I18n.t('End project')}
        description={`${I18n.t('You are about to end a project')} ${I18n.t(
          'All active invites will be deactivated and unused credits released',
        )} ${I18n.t('It will be not possible to revert the action')}`}
        onClose={() => closeModal(actions.END_ASSESSMENT_PROJECT)}
        onConfirm={completeProject}
        caution
        confirmText={I18n.t('Continue')}
        cancelText={I18n.t('Cancel')}
      />

      <ConfirmationModal
        isVisible={modalsState[actions.ASSESSMENT_PROJECT_SEND_REMINDERS]}
        title={I18n.t('Send reminder')}
        description={`${I18n.t('You are about to send reminder to the selected respondents')}. ${I18n.t(
          'Press “Yes” to continue',
        )}.`}
        onClose={() => closeModal(actions.ASSESSMENT_PROJECT_SEND_REMINDERS)}
        onConfirm={sendReminders}
        caution
        confirmText={I18n.t('Yes')}
        cancelText={I18n.t('Cancel')}
      />

      {modalsState[actions.ASSESSMENT_PROJECT_ADD_RESPONDENT] && (
        <AssessmentProjectAddRespondentsModal
          onClose={() => closeModal(actions.ASSESSMENT_PROJECT_ADD_RESPONDENT)}
          projectId={projectId}
          onSuccess={() => {
            setShouldResetPageStatus(true);
            fetchProject();
          }}
          costPerRespondent={activeProjectInView?.creditsPerRespondent}
        />
      )}
      {modalsState[actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS] && (
        <ImportRespondentsModal
          onClose={() => closeModal(actions.ASSESSMENT_PROJECT_IMPORT_RESPONDENTS)}
          onSuccess={() => {
            setShouldResetPageStatus(true);
            fetchProject();
          }}
          isAssessmentProject
          projectId={projectId}
        />
      )}
      {modalsState[actions.NO_PROJECT_MODAL] && <NoProjectModal onClose={() => closeModal(actions.NO_PROJECT_MODAL)} />}
    </PageWrapperForProjects>
  );
};

AssessmentProject.propTypes = {
  projectId: oneOfType([string, number]).isRequired,
};

const TableHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const TableName = styled.h3`
  font-size: 20px;
  color: ${(props) => props.theme.colors.darkBlue2};
  font-weight: bold;
  margin-right: 1.6rem;
`;

const ProjectCost = styled.div`
  color: ${(props) => props.theme.colors.darkBlue2};
  font-size: 14px;
  font-weight: 500;

  span:last-of-type {
    font-weight: 600;
    margin-left: 0.5rem;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(CustomButton)`
  margin-left: 1rem;
`;

const StyledTable = styled(Table)`
  tr:hover {
    & > div div {
      background: rgba(241, 241, 241, 0.1);
    }
  }
`;
const TitleComponent = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;

const EmptyMessageTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const EmptyMessageTitle = styled.h5`
  font-size: ${(props) => (props.minimize ? '1.8rem' : '2.2rem')};
  font-weight: bold;
  margin: 1.5rem 0;
`;

const StyledNoResultsIcon = styled(NoResultsIcon)`
  width: 8rem;
  height: 8rem;
`;

const NoRespondentsMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: 2rem;
    font-weight: bold;
    margin: 1.6rem 0;
  }

  div:last-child {
    color: rgba(0, 0, 0, 0.4);
    font-size: ${(props) => props.theme.fontSizes.normal};
    line-height: 2.4rem;
    font-weight: 400;
  }
`;

const StyledStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledStatus = styled.div`
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};
`;

const InviteNotSent = styled.span`
  color: #ffa000;
  font-weight: 600;
  font-size: 14px;
`;

const ReportStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(props) => props.color || props.theme.colors.primary};
`;

const RedWarningIcon = styled(WarningIcon)`
  margin-left: 0.6rem;
  path {
    fill: ${(props) => props.theme.colors.error};
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  width: 29rem;
`;

export default AssessmentProject;
