import React, { useMemo, useEffect, useState } from 'react';
import { push } from 'redux-first-history';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../lib/ag-grid';
import PageWrapper from '../../PageWrapper';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import Spinner from '../../reusable/Spinner';
import AddIdealProfileModal from './AddIdealProfile';
import useModals from '../../../hooks/useModals';
import * as actions from '../../../table/actionsList';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import idealProfilesTableActions from '../../../table/tableActions/idealProfiles';
import idealProfilesBulkActions from '../../../table/bulkActions/idealProfiles';
import {
  fetchIdealProfiles,
  fetchIdealProfilesTests,
  deleteIdealProfile,
} from '../../../store/settings/ideal-profiles/actions';
import { selectIdealProfileTestsOptions } from '../../../store/settings/ideal-profiles/selectors';
import TableTabs from '../../reusable/TableTabs';
import idealProfileTypes from '../../../constants/idealProfileTypes';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const IdealProfiles = () => {
  const dispatch = useDispatch();

  const idealProfiles = useSelector((state) => state.idealProfiles.idealProfiles);
  const idealProfileTests = useSelector(selectIdealProfileTestsOptions);

  const modals = [actions.ADD_NEW_IDEAL_PROFILE, actions.DELETE_IDEAL_PROFILES];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const [selectedIdealProfiles, setSelectedIdealProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchIdealProfilesTests());
    dispatch(
      fetchIdealProfiles(() => {
        setIsLoading(false);
      }),
    );
  }, []);

  const onIdealProfilesDelete = () => {
    dispatch(
      deleteIdealProfile(selectedIdealProfiles, () => {
        closeModal(actions.DELETE_IDEAL_PROFILES);
        setSelectedIdealProfiles([]);
      }),
    );
  };

  const navigateToNorm = (idealProfileID) => {
    dispatch(push(`/settings/ideal-profiles/${idealProfileID}`));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedIdealProfiles });

  const bulkActionsHandlers = {
    [actions.EDIT_IDEAL_PROFILE]: () => navigateToNorm(selectedIdealProfiles[0]),
    [actions.DELETE_IDEAL_PROFILES]: () => openModal(actions.DELETE_IDEAL_PROFILES),
  };

  const bulkActions = useMemo(() => {
    return idealProfilesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
      isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled(selectedIdealProfiles) : item.isDisabled,
    }));
  }, [selectedIdealProfiles]);

  const tableActions = useMemo(() => {
    return idealProfilesTableActions.map((item) => ({
      ...item,
      handler: () => openModal(item.name),
    }));
  }, []);

  const tabs = [
    { name: 'assessments-and-batteries', label: 'Assessment and Batteries' },
    { name: 'ideal-profiles', label: 'Ideal Profiles' },
    { name: 'norms', label: 'Norms' },
  ];

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data.idealProfileID);
        }
      });
      setSelectedIdealProfiles(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: 'Name',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Type',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: (params) =>
        idealProfileTypes[params.data.type] ? idealProfileTypes[params.data.type] : params.data.type,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      sort: 'desc',
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'testID',
      headerName: 'Questionnaire',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToNorm(event.data.idealProfileID);
  };

  const onFirstDataRendered = (params) => {
    params.api.getFilterInstance('testID', (filterInstance) => {
      filterInstance.setModel({ values: ['15FQ+'] });
      params.api.onFilterChanged();
    });
  };

  return (
    <PageWrapper
      title={I18n.t('Assessment settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
      style={{ overflowY: 'auto' }}
    >
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <TableTabs
        tabs={tabs}
        activeTab={tabs[1]}
        clickHandler={(tab) => {
          dispatch(push(tab.name));
        }}
      />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : idealProfiles}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_IDEAL_PROFILES]}
        title={I18n.t('Delete ideal profiles')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedIdealProfiles.length > 1 ? I18n.t('selected ideal profiles?') : I18n.t('this ideal profile?')
        }`}
        onClose={() => closeModal(actions.DELETE_IDEAL_PROFILES)}
        onConfirm={onIdealProfilesDelete}
        caution
      />
      {modalsState[actions.ADD_NEW_IDEAL_PROFILE] && (
        <AddIdealProfileModal onClose={() => closeModal(actions.ADD_NEW_IDEAL_PROFILE)} tests={idealProfileTests} />
      )}

      <Spinner isLoading={isLoading} full />
    </PageWrapper>
  );
};

export default IdealProfiles;
