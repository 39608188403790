import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { push } from 'redux-first-history';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../lib/ag-grid';
import PageWrapper from '../PageWrapper';
import TableTabs from '../reusable/TableTabs';
import AddNewGroup from './AddNewGroup';
import ManageGroupDetails from './ManageGroupDetails';
import GroupBasedIdealProfile from './GroupBasedIdealProfile';
import GroupBasedNormCreation from './GroupBasedNormCreation';
import AdministerManagedGroup from './AdministerManagedGroup';
import ManageActiveLinks from './ManageActiveLinks';
import GroupInvite from './GroupInvite';
import GroupRequestReport from './GroupRequestReport';
import AdministerGroupSession from './AdministerGroupSession';
import BulkActionsSidebar from '../BulkActionsSidebar';
import {
  setSelectedGroups,
  fetchGroups,
  deleteGroups,
  changeShouldResetGroupsTableBoolState,
} from '../../store/groups/actions';
import * as actions from '../../table/actionsList';
import groupsTableActions from '../../table/tableActions/groups';
import groupsBulkActions from '../../table/bulkActions/groups';
import useModals from '../../hooks/useModals';
import useBulkSidebarState from '../../hooks/useBulkSidebarState';
import ConfirmationModal from '../reusable/ConfirmationModal';
import groupsTypesMap from '../../constants/groupTypes';
import Members from '../reusable/Members';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const Groups = () => {
  const groups = useSelector((state) => state.groups.groups);
  const { user, partnerInfo } = useSelector((state) => state.user);
  const selectedGroups = useSelector((state) => state.groups.selectedGroups);
  const totalCount = useSelector((state) => state.groups.totalCount);
  const shouldResetTableState = useSelector((state) => state.groups.shouldResetTableState);
  const respondentsTotalCount = useSelector((state) => state.respondents.totalCount);

  const dispatch = useDispatch();

  const gridRef = useRef();

  const [renderKey, setRenderKey] = useState(1);
  useEffect(() => {
    if (shouldResetTableState) {
      setRenderKey(Math.random() * 1000);
      dispatch(changeShouldResetGroupsTableBoolState(false));
    }
  }, [shouldResetTableState]);


  const [isLoading, setLoadingStatus] = useState(true);
  const fetchData = useCallback(({ tableData, shouldReset, showSpinner, tableCallback }) => {
    if (showSpinner) setLoadingStatus(true);
    dispatch(
      fetchGroups(tableData, {
        callback: () => {
          setLoadingStatus(false);
          // eslint-disable-next-line no-unused-expressions
          tableCallback && tableCallback();
        },
        shouldReset,
      }),
    );
  }, []);

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedGroups });

  const modals = [
    actions.EDIT_GROUP_DETAILS,
    actions.ADMINISTER_GROUP_SESSION,
    actions.CREATE_GROUP,
    actions.GROUP_CREATE_INVITE,
    actions.GROUP_REQUEST_REPORT,
    actions.DELETE_GROUPS,
    actions.ADMINISTER_MANAGED_GROUP,
    actions.CREATE_PROFILE_BASED_ON_GROUP,
    actions.CREATE_NORM_BASED_ON_GROUP,
    actions.MANAGE_ACTIVE_LINKS,
  ];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const navigateToEdit = (group) => {
    const path = group.hasActiveAdministeredSession
      ? `/groups/administer/${group.groupID}`
      : `/groups/${group.groupID}`;

    dispatch(push(path));
    dispatch(setSelectedGroups([]));
  };

  // Table actions
  const tableHandlers = {
    [actions.CREATE_GROUP]: () => openModal(actions.CREATE_GROUP),
    [actions.IMPORT_GROUP]: () => {},
  };

  const tableActions = useMemo(() => {
    return groupsTableActions.map((item) => ({ ...item, handler: tableHandlers[item.name] }));
  }, []);

  // bulk actions
  const bulkActions = useMemo(() => {
    return groupsBulkActions(user).map((item) => ({
      ...item,
      handler: () => openModal(item.name),
      isDisabled:
        typeof item.isDisabled === 'function'
          ? item.isDisabled(selectedGroups, user, partnerInfo?.switchFeatures)
          : item.isDisabled,
    }));
  }, [selectedGroups, user]);

  const tabs = useMemo(() => {
    return [
      { name: 'respondents', label: `${I18n.t('List')} (${respondentsTotalCount})` },
      { name: 'groups', label: `${I18n.t('Groups')} (${totalCount})` },
    ];
  }, [totalCount, respondentsTotalCount]);

  const onTabClick = (tab) => {
    if (tab.name === 'respondents') dispatch(push('/respondents'));
  };

  const onGroupsDelete = useCallback(() => {
    dispatch(
      deleteGroups(
        selectedGroups.map((group) => group.groupID),
        () => closeModal(actions.DELETE_GROUPS),
      ),
    );
  }, [selectedGroups]);

  const handleReset = () => {
    setRenderKey(Math.random() * 1000);
    dispatch(
      fetchGroups('', {
        callback: () => {
          setLoadingStatus(true);
        },
        shouldReset: true,
      }),
    );
  };

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      dispatch(setSelectedGroups(selectedData));
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'description',
      headerName: 'Name',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'notes',
      headerName: 'Description',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 2,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Type',
      cellDataType: 'text',
      filter: 'agSetColumnFilter',
      flex: 1,
      valueGetter: (params) => I18n.t(groupsTypesMap[params.data.groupType]),
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Members',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      valueGetter: (params) => params.data.respondents.length,
      cellRenderer: ({ data }) => {
        return <Members members={data.respondents} />;
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      cellDataType: 'date',
      flex: 1,
      filter: 'agDateColumnFilter',
      sort: 'desc',
      valueGetter: (params) => {
        return new Date(params.data.creationDate);
      },
      valueFormatter: dateValueFormatter,
      filterParams: {
        maxNumConditions: 1,
        comparator: dateWithoutTimeComparator,
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateToEdit(event.data);
  };

  useEffect(() => {
    fetchData({ shouldReset: true, showSpinner: true });
  }, []);

  useEffect(() => {
    if (!isLoading && gridRef.current) {
      setLoadingStatus(true);
        fetchData({
          shouldReset: true,
          showSpinner: true,
          tableCallback: () => {
            if (gridRef.current?.api) {
              gridRef.current.api.setGridOption('rowData', groups);
              gridRef.current.api.refreshCells();
            }
          },
        });
    }
  }, [renderKey]);

  return (
    <PageWrapper  title={I18n.t('Respondents')} buttons={tableActions} style={{ overflowY: 'auto' }}>
      <TableTabs tabs={tabs} activeTab={tabs[1]} clickHandler={onTabClick} />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : groups}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          onFilterChanged={handleFilterChanged}
          loading={isLoading ? true : false}
          localeText={currentLocaleText}
        />
      </div>
      {modalsState[actions.EDIT_GROUP_DETAILS] && (
        <ManageGroupDetails
          group={selectedGroups[0]}
          actionType="0"
          onClose={() => {
            closeModal(actions.EDIT_GROUP_DETAILS);
            handleReset();
          }}
        />
      )}
      {modalsState[actions.DUPLICATE_GROUP] && (
        <ManageGroupDetails
          group={selectedGroups[0]}
          actionType="1"
          onClose={() => {
            closeModal(actions.DUPLICATE_GROUP);
            handleReset();
          }}
        />
      )}
      {modalsState[actions.CREATE_GROUP] && <AddNewGroup onClose={() => closeModal(actions.CREATE_GROUP)} />}
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_GROUPS]}
        title={I18n.t('Delete group')}
        description={I18n.t('Are you sure you want to delete this group(s)')}
        onClose={() => closeModal(actions.DELETE_GROUPS)}
        onConfirm={onGroupsDelete}
        caution
      />
      {modalsState[actions.GROUP_CREATE_INVITE] && (
        <GroupInvite onClose={() => closeModal(actions.GROUP_CREATE_INVITE)} group={selectedGroups[0]} />
      )}
      {modalsState[actions.GROUP_REQUEST_REPORT] && (
        <GroupRequestReport
          onClose={() => closeModal(actions.GROUP_REQUEST_REPORT)}
          group={selectedGroups[0]}
          successCallback={() => dispatch(changeShouldResetGroupsTableBoolState(true))}
        />
      )}
      {modalsState[actions.CREATE_PROFILE_BASED_ON_GROUP] && (
        <GroupBasedIdealProfile
          onClose={() => closeModal(actions.CREATE_PROFILE_BASED_ON_GROUP)}
          groupsIds={selectedGroups.map((item) => item.groupID)}
        />
      )}
      {modalsState[actions.CREATE_NORM_BASED_ON_GROUP] && (
        <GroupBasedNormCreation
          onClose={() => closeModal(actions.CREATE_NORM_BASED_ON_GROUP)}
          groupsIds={selectedGroups.map((item) => item.groupID)}
        />
      )}
      {modalsState[actions.ADMINISTER_MANAGED_GROUP] && (
        <AdministerManagedGroup
          onClose={() => closeModal(actions.ADMINISTER_MANAGED_GROUP)}
          group={selectedGroups[0]}
        />
      )}
      {modalsState[actions.MANAGE_ACTIVE_LINKS] && (
        <ManageActiveLinks onClose={() => closeModal(actions.MANAGE_ACTIVE_LINKS)} />
      )}
      {modalsState[actions.ADMINISTER_GROUP_SESSION] && (
        <AdministerGroupSession
          onClose={() => closeModal(actions.ADMINISTER_GROUP_SESSION)}
          group={selectedGroups[0]}
        />
      )}
    </PageWrapper>
  );
};

export default Groups;
