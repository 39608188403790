import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../../lib/ag-grid';
import PageWrapper from '../../PageWrapper';
import SimplifiedTable from '../../reusable/Tables/SimplifiedTable';
import TableTabs from '../../reusable/TableTabs';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import welcomeTextsTableActions from '../../../table/tableActions/welcomeTexts';
import welcomeTextsBulkActions from '../../../table/bulkActions/welcomeTexts';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import * as actions from '../../../table/actionsList';
import useModals from '../../../hooks/useModals';
import templatesTabs from './templatesTabs';
import { fetchWelcomeTexts, deleteWelcomeTexts } from '../../../store/settings/welcome-texts/actions';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const WelcomeTexts = () => {
  const welcomeTexts = useSelector((state) => state.welcomeTexts.welcomeTexts);
  const dispatch = useDispatch();

  const [isLoading, setLoadingState] = useState(true);

  useEffect(() => {
    dispatch(
      fetchWelcomeTexts(() => {
        setLoadingState(false);
      }),
    );
  }, []);

  const [selectedWelcomeTexts, setSelectedWelcomeTexts] = useState([]);

  const modals = [actions.CREATE_WELCOME_TEXT, actions.DELETE_WELCOME_TEXTS];
  const { modalsState, openModal, closeModal } = useModals(modals);

  const goBackToSettings = () => dispatch(push('/settings'));

  const onWelcomeTextsDelete = () => {
    dispatch(
      deleteWelcomeTexts(
        selectedWelcomeTexts.map((item) => item.templateID),
        () => {
          closeModal(actions.DELETE_WELCOME_TEXTS);
        },
      ),
    );
  };

  const onTabClick = (tab) => {
    if (tab.name === 'templates') dispatch(push('/settings/templates'));
  };

  // Controlling bulk actions sidebar state
  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedWelcomeTexts });

  const navigateToWelcomeText = (welcomeText) => dispatch(push(`/settings/welcome-texts/${welcomeText.templateID}`));

  const bulkActionsHandlers = {
    [actions.EDIT_WELCOME_TEXT]: () => navigateToWelcomeText(selectedWelcomeTexts[0]),
    [actions.DELETE_WELCOME_TEXTS]: () => openModal(actions.DELETE_WELCOME_TEXTS),
  };

  const bulkActions = useMemo(() => {
    return welcomeTextsBulkActions.map((item) => ({ ...item, handler: bulkActionsHandlers[item.name] }));
  }, [selectedWelcomeTexts]);

  const tableActions = useMemo(() => {
    return welcomeTextsTableActions.map((item) => ({
      ...item,
      handler: () => dispatch(push('/settings/welcome-texts/new')),
    }));
  }, []);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onRowSelected: (event) => {
      const found = selectedWelcomeTexts.findIndex((entry) => entry === event.node.data);
      if (event.node.selected) {
        if (found === -1) setSelectedWelcomeTexts(selectedWelcomeTexts.concat(event.node.data));
      } else {
        if (found !== -1)
          setSelectedWelcomeTexts([...selectedWelcomeTexts.slice(0, found), ...selectedWelcomeTexts.slice(found + 1)]);
      }
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const columnDefinition = [
    {
      field: 'name',
      headerName: 'Name',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ];

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleRowClick = (event) => navigateToWelcomeText(event.data);

  return (
    <PageWrapper
      backButtonHandler={goBackToSettings}
      buttons={tableActions}
      title={I18n.t('Welcome texts')}
      style={{ overflowY: 'auto' }}
    >
      <BulkActionsSidebar onClose={closeSidebar} isOpen={isSidebarOpen} actions={bulkActions} />
      <TableTabs tabs={templatesTabs} activeTab={templatesTabs[1]} clickHandler={onTabClick} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : welcomeTexts}
          rowSelection={rowSelection}
          onRowClicked={handleRowClick}
          localeText={currentLocaleText}
        />
      </div>
      <ConfirmationModal
        isVisible={modalsState[actions.DELETE_WELCOME_TEXTS]}
        title={I18n.t('Delete welcome texts')}
        description={`${I18n.t('Are you sure you want to delete')} ${
          selectedWelcomeTexts.length > 1 ? I18n.t('selected welcome texts?') : I18n.t('this welcome text?')
        }`}
        onClose={() => closeModal(actions.DELETE_WELCOME_TEXTS)}
        onConfirm={onWelcomeTextsDelete}
        caution
      />
    </PageWrapper>
  );
};

WelcomeTexts.propTypes = {};

const Table = styled(SimplifiedTable)`
  td:first-child,
  th:first-child {
    width: 3%;
  }
`;

export default WelcomeTexts;
