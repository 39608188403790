import React, { useRef, useCallback, useEffect, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { utils, writeFile } from 'xlsx';
import { dateValueFormatter, dateWithoutTimeComparator, currentLocaleText } from '../../../../lib/ag-grid';
import apiInstance2 from '../../../../services/apiService';
import useUpdateEffect from '../../../../hooks/useUpdateEffect';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  StatusBarModule,
  SetFilterModule,
  ExcelExportModule,
  RowGroupingModule,
  MasterDetailModule,
]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const agGridDefaultSettings = {
  pagination: true,
  paginationPageSize: 100,
  paginationPageSizeSelector: [20, 50, 100],
  rowHeight: '58',
  headerHeight: '58',
  domLayout: 'autoHeight',
  gridOptions: { popupParent: document.body },
};

const agGriddefaultColumnDefinition = {
  sortable: true,
};

const columnDefinition = [
  {
    cellRenderer: 'agGroupCellRenderer',
    field: 'paymentDate',
    headerName: 'Request Date',
    cellDataType: 'date',
    flex: 1,
    filter: 'agDateColumnFilter',
    valueGetter: (params) => {
      return new Date(params.data.paymentDate);
    },
    valueFormatter: dateValueFormatter,
    filterParams: {
      maxNumConditions: 1,
      comparator: dateWithoutTimeComparator,
      buttons: ['apply', 'reset'],
      closeOnApply: true,
    },
  },
  {
    field: 'creditsUsed',
    headerName: 'Credits Paid',
    cellDataType: 'number',
    filter: 'agNumberColumnFilter',
    flex: 1,
    filterParams: {
      buttons: ['apply', 'reset'],
      closeOnApply: true,
    },
  },
];
const detailCellRendererParamsLevel2 = {
  detailGridOptions: {
    columnDefs: [
      {
        headerName: 'Respondent',
        cellDataType: 'text',
        filter: 'agTextColumnFilter',
        flex: 1,
        valueFormatter: (params) => {
          return `${params.data.firstName} ${params.data.familyName}`;
        },
        filterParams: {
          buttons: ['apply', 'reset'],
          closeOnApply: true,
        },
      },
    ],
    defaultColDef: {
      flex: 1,
    },
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.respondents);
  },
};

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: [
      {
        cellRenderer: 'agGroupCellRenderer',
        field: 'reportName',
        headerName: 'Test',
        cellDataType: 'text',
        filter: 'agSetColumnFilter',
        flex: 1,
        filterParams: {
          buttons: ['apply', 'reset'],
          closeOnApply: true,
        },
      },
    ],
    masterDetail: true,
    detailCellRendererParams: detailCellRendererParamsLevel2,
    defaultColDef: {
      flex: 1,
    },
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.reportRequests);
  },
};

export default function({ exportTrigger, onRowsLoaded }) {
  const gridRef = useRef(null);

  const [tableRows, setTableRows] = useState(null);

  const collectDataForExport = () => {
    const rows = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      const row = {
        ['Request Date']: new Date(node.data.paymentDate),
        ['Credits Paid']: node.data.creditsUsed,
      };

      if (node.data.reportRequests) {
        node.data.reportRequests.forEach((detail, idx) => {
          row['Report Requested'] = detail.reportName;
          if (detail.respondents.length) {
            detail.respondents.forEach((respondent, idxx) => {
              if (idx == 0 && idxx === 0) {
                row['Respondent'] = `${respondent.firstName} ${respondent.familyName}`;
                rows.push(row);
              } else if (idxx == 0) {
                rows.push({
                  ['Request Date']: '',
                  ['Credits Paid']: '',
                  ['Report Requested']: detail.reportName,
                  ['Respondent']: `${respondent.firstName} ${respondent.familyName}`,
                });
              } else {
                rows.push({
                  ['Request Date']: '',
                  ['Credits Paid']: '',
                  ['Report Requested']: '',
                  ['Respondent']: `${respondent.firstName} ${respondent.familyName}`,
                });
              }
            });
          }
        });
      }
      rows.push({
        ['Request Date']: '',
        ['Credits Paid']: '',
        ['Report Requested']: '',
        ['Respondent']: '',
      });
    });

    return rows;
  };

  const exportDataToExcel = useCallback(() => {
    const data = collectDataForExport();
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'ExportedData');
    writeFile(workbook, 'Export.xlsx');
  }, []);

  useEffect(() => {
    (async () => {
      const endDate = new Date().toISOString();
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      const startDateISO = startDate.toISOString();

      const response = await apiInstance2.post('/api/v2/partner/account-activity-log', {
        accountId: 4362,
        distributorId: 667,
        userID: 16574,
        startDate: startDateISO,
        endDate: endDate,
      });

      onRowsLoaded();

      const reports = response?.data?.data?.reportsRequested?.[0]?.reportRequestPayment;
      if (reports) {
        setTableRows(reports);
      } else {
        setTableRows([]);
      }
    })();
  }, []);

  useUpdateEffect(() => {
    exportDataToExcel();
  }, [exportTrigger]);

  return (
    <div>
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          ref={gridRef}
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          masterDetail={true}
          detailCellRendererParams={detailCellRendererParams}
          rowData={tableRows}
          localeText={currentLocaleText}
        />
      </div>
    </div>
  );
}
