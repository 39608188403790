import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { I18n } from 'react-redux-i18n';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { currentLocaleText } from '../../../lib/ag-grid';
import PageWrapper from '../../PageWrapper';
import {
  addNewResponseScale,
  deleteResponseScalesAction,
  fetchResponseScalesAction,
  resetResponseScales,
} from '../../../store/settings/response-scales/actions';
import responseScalesSelectors from '../../../store/settings/response-scales/selectors';
import * as actions from '../../../table/actionsList';
import responseScalesTableActions from '../../../table/tableActions/responseScales';
import SettingsTabs from '../components/SettingsTabs/SettingsTabs';
import AddResponseScale from '../AddRespondentScale/AddResponseScale';
import BulkActionsSidebar from '../../BulkActionsSidebar';
import useBulkSidebarState from '../../../hooks/useBulkSidebarState';
import useModals from '../../../hooks/useModals';
import ConfirmationModal from '../../reusable/ConfirmationModal';
import responseScalesBulkActions from './bulkActions';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, SetFilterModule]);
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-068280}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Psytech_International_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GeneSys}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GeneSys}_need_to_be_licensed___{GeneSys}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_October_2025}____[v3]_[01]_MTc1OTUzMjQwMDAwMA==7bc27880cfb7d8f1c736be6bd6fb9894',
);

const ResponseScales = () => {
  const dispatch = useDispatch();

  const [isModalOpened, setIsModalOpened] = useState(false);

  const scales = useSelector(responseScalesSelectors.selectScales);
  const isLoading = useSelector(responseScalesSelectors.selectIsLoading);
  const selectedScales = useSelector((state) => state.responseScales.selectedScales);
  const [selected, setSelected] = useState([]);

  const { isSidebarOpen, closeSidebar } = useBulkSidebarState({ items: selectedScales });

  const modals = [actions.REMOVE_RESPONSE_SCALES];
  const { modalsState, openModal, closeModal } = useModals(modals);

  useEffect(() => {
    dispatch(fetchResponseScalesAction());

    return () => {
      dispatch(resetResponseScales());
    };
  }, []);

  const tableActions = useMemo(() => {
    return responseScalesTableActions.map((item) => ({
      ...item,
      handler: () => dispatch(push('add-response-scale')),
    }));
  }, []);

  const onHandleClosePopup = (data) => {
    dispatch(addNewResponseScale(data));
    setIsModalOpened(false);
  };

  const navigateScaleDetails = ({ responseScaleID }) => {
    dispatch(push(`/settings/response-scales/${responseScaleID}`));
  };

  // BULK ACTIONS
  const bulkActionsHandlers = {
    [actions.REMOVE_RESPONSE_SCALES]: () => openModal(actions.REMOVE_RESPONSE_SCALES),
  };

  const bulkActions = useMemo(() => {
    return responseScalesBulkActions.map((item) => ({
      ...item,
      handler: bulkActionsHandlers[item.name],
    }));
  }, [selectedScales]);

  const onScaleDelete = useCallback(() => {
    dispatch(
      deleteResponseScalesAction(
        selectedScales.map((scale) => scale.responseScaleID),
        () => {
          closeModal(actions.REMOVE_RESPONSE_SCALES);
        },
      ),
    );
  }, [selectedScales]);

  const agGridDefaultSettings = {
    pagination: true,
    paginationPageSize: 100,
    paginationPageSizeSelector: [20, 50, 100],
    rowHeight: '58',
    headerHeight: '58',
    domLayout: 'autoHeight',
    gridOptions: { popupParent: document.body },
    onSelectionChanged: (event) => {
      const selectedData = [];
      event.api.forEachNodeAfterFilter((node) => {
        if (node.isSelected()) {
          selectedData.push(node.data);
        }
      });
      setSelected(selectedData);
    },
  };

  const agGriddefaultColumnDefinition = {
    sortable: true,
  };

  const [columnDefinition] = useState([
    {
      field: 'name',
      headerName: 'Template name',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Responses',
      cellDataType: 'number',
      filter: 'agNumberColumnFilter',
      flex: 1,
      valueGetter: (params) => {
        return params.data.languages[0]?.responses?.length || 0;
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
    {
      headerName: 'Languages',
      cellDataType: 'text',
      filter: 'agTextColumnFilter',
      flex: 1,
      valueGetter: (params) => {
        return params.data.languages.map((language) => language.langID).join(', ');
      },
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },
  ]);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
    };
  }, []);

  const handleFilterChanged = (params) => {
    params.api.deselectAll();
    params.api.refreshHeader();
  };

  const handleRowClick = (event) => {
    if (
      event.event?.target?.closest('[col-id="ag-Grid-ControlsColumn"]')?.getAttribute('col-id') !==
      'ag-Grid-ControlsColumn'
    )
      navigateScaleDetails(event.data);
  };

  return (
    <PageWrapper
      title={I18n.t('360 settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
      style={{ overflowY: 'auto' }}
    >
      <SettingsTabs />
      <BulkActionsSidebar isOpen={isSidebarOpen} onClose={closeSidebar} actions={bulkActions} />
      <div className="ag-theme-quartz ag-grid-custom-styles">
        <AgGridReact
          {...agGridDefaultSettings}
          defaultColDef={agGriddefaultColumnDefinition}
          columnDefs={columnDefinition}
          rowData={isLoading ? null : scales}
          rowSelection={rowSelection}
          onFilterChanged={handleFilterChanged}
          onRowClicked={handleRowClick}
          localeText={currentLocaleText}
        />
      </div>
      {isModalOpened && <AddResponseScale onClose={onHandleClosePopup} />}
      <ConfirmationModal
        isVisible={modalsState[actions.REMOVE_RESPONSE_SCALES]}
        title={I18n.t('Delete scale(s)')}
        description={I18n.t('Are you sure you want to delete this scale(s)?')}
        onClose={() => closeModal(actions.REMOVE_RESPONSE_SCALES)}
        onConfirm={onScaleDelete}
        caution
      />
    </PageWrapper>
  );
};

export default ResponseScales;
